<template>
    <div class="vote" >
        <div class="nav">
            <van-row>
                <van-col span="4">
                    <div @click="back">
                        <van-icon name="arrow-left" color="#fff" />
                    </div>
                </van-col>
                <van-col span="16">
                    <text style="color:#fff">{{lang.program_voting}}</text>
                </van-col>
                <van-col span="4">
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>
        <van-row align="center" class="info">
            <van-col span="24">
                {{lang.remainingVotes}}: {{vote_times-voted_count}}
            </van-col>
        </van-row>
        <div class="main">
            <van-row>
                <van-col offset="1" span="22">
                    <div v-for="item,index in votingList" :key="item" :index="index" class="row">
                        <van-row @click="takeOne(item)">
                            <van-col span="2">
                                <div class="number">{{index+1}}</div>
                            </van-col>
                            <van-col offset="1" span="12">
                                <div class="title">{{common.changeLanguage(item.en_title,item.cn_title,lang.language)}}</div>
                            </van-col>
                            <van-col offset="1" span="8">
                                <van-row>
                                    <div class="vote-btn" >
                                        <img :src="common.getRes( isVoted(item.id) ? lang.program_voting_voted : lang.program_voting_vote )"/>
                                    </div>
                                    <div class="center marinTop" style="width:100%;">
                                        <div class="total_of_str">{{lang.total_of}} </div> 
                                        <div class="total_of_num"> {{item.count}} </div>
                                        <div class="total_of_str"> {{lang.votes}}</div>
                                    </div>
                                </van-row> 
                            </van-col>
                        </van-row>
                    </div>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs,onMounted } from 'vue';
import Common from "../library/Common";
import Consts from "../library/Consts";
import { useRouter } from 'vue-router';
import myRequest from '../library/MyRequest';
import { Toast } from 'vant';
import "../css/font.css";

export default {
    name: 'vote',
    setup(){
        const router = useRouter();
        const voteStatus = Consts.voteStatus;
        const common = Common;
        const userInfo = common.getUserInfo();
        const program_id = router.currentRoute.value.params.id;
        if(userInfo == null){
            router.push({name: 'login'});
        }
        
        let state = reactive({
            lang:{
                langBtn:"",
                program_voting:"",
                program_voting_vote:"",
                program_voting_voted:"",
                total_of:"",
                votes:"",
                remainingVotes:"",
                language:"",
            },
            votingList:[],
            votedList:[],       //已投票id列表
            vote_times:0,       //允许投票总数
            voted_count:0,      //已投票总数
            activity:null,
        });
        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.lang.language = common.getCurrentLanguage();
            state.lang.langBtn = common.getLanguageBtn();
        }
        
        const back = function(){
            router.back(-1);
        }

        const getFromCode =async()=>{
            var res = await myRequest.get(`activity/program/from-code/?activity_id=${userInfo.ticket.activity}`);
            if(res.status == 200){
                state.activity = res.data;
                return true;
            }
            return false;
        }

        const takeOne = async(item)=>{
        
            //检查活动是否进行中
            if(!await getFromCode()){
                Toast.fail({
                    message:common.getLanguage("networdError"),
                    forbidClick:true,
                    overlay:false,
                    duration:3000,
                })
                return ;
            }

            //检查活动是否开启投票
            if(!state.activity.voting.has){
                Toast.fail({
                    message:common.getLanguage("votingIsOver"),
                    forbidClick:true,
                    overlay:false,
                    duration:3000,
                    onClose:function(){
                        back();
                    }
                });
                return ;
            }

            //检查活动投票是否更改
            if(state.activity.voting.id != program_id){
                Toast.fail({
                    message:common.getLanguage("votingHasEnded"),
                    forbidClick:true,
                    overlay:false,
                    duration:4000,
                    onClose:function(){
                        back();
                    }
                });
                return ;
            }

            //检查活动是否在允许投票状态
            let status = await getVoteStatus()
            if(status!=voteStatus.start){
                Toast.fail({
                    message:getVoteStatusMsg(status),
                    forbidClick:true,
                    overlay:false,
                    duration:3000,
                });
                return ;
            }

            //检查该项是否已被投票
            if(isVoted(item.id)) return ;

            var send ={
                ticket_id:userInfo.ticket.id,
                topic_id:item.id,
                program_id:program_id
            }
            try{
                var res = await myRequest.post(`activity/program/voting/`,send);
                if(res.status == 200){
                    item.count++;
                    state.votedList.push(item.id);
                    state.voted_count++;
                }
            }catch(e){
                if(e.response.status == 400){
                    Toast(common.getLanguage('beenExhausted'));
                }
            }
        }

        const getVoteStatusMsg = function(status){
            switch(status){
                case voteStatus.pending:
                    return common.getLanguage('voting_is_pending');
                case voteStatus.concluded:
                    return common.getLanguage('voting_concluded');
                default:
                    return common.getLanguage('networdError');
            }
        }
        const getVoteStatus = async()=>{
            var res = await myRequest.get(`activity/program/${program_id}/`);
            if(res.status == 200){
               return res.data.status;
            }
            return voteStatus.netError;
        }

        const getVoteList = async()=>{
            var res = await myRequest.get(`activity/program/${program_id}/`);
            if(res.status == 200){
                state.votingList = res.data.voting;
                state.vote_times = res.data.vote_times;
                var votedRes = await myRequest.get(`/activity/program/voted/?program_id=${program_id}&&ticket_id=${userInfo.ticket.id}`);
                if(votedRes.status == 200){
                    state.voted_count = votedRes.data.voted_count;
                    state.votedList = votedRes.data.topics.map((item)=>{
                        return item.topic.id
                    });
                }
            }
        }

        const isVoted = function(id){
            return state.votedList.indexOf(id)>-1
        }

        onMounted(()=>{
            state.lang.language = common.getCurrentLanguage();
            getVoteList();
        })
        
        return {
            ...toRefs(state),
            common,
            toggleLanguage,
            back,
            takeOne,
            isVoted
        };
    }
}
</script>

<style scoped>
.vote{
    width: 100%;
    height: 100%;
    background-color: #2A3F54;
}
.info{
    color:#fff;
    font-size: 24px;
    height: 48px;
    background: rgba(251,89,118, 0.726);
}
.nav{
    background-color: #2A3F54;
}
.main{
    height:calc(100% - 110px -35px);
    text-align: left;
    overflow-y:auto;
}
.marinTop{
    margin-top:-20px;
}
.number{
  margin-top:30px;
  font-family: 'Biotif-BlackItalic';
  border-radius: 100%;
  text-align: center;
  color:#fff;
  width: 50px;
  height: 50px;
  line-height: 60px;
  font-size: 36px;
  background-color: #D4AB85;
}
.title{
  margin-top:30px;
  font-size:30px;
  color: #fff;
}
.row{
    padding:10px 0;
}

.vote-btn img{
  width: 100%;
}

.total_of_str,.total_of_num{
  font-size: 24px;
  
  display: inline-block;
}
.total_of_str{
  color:#A4A4A4;
  margin:0 10px;
}
.total_of_num{
  color:#FB5877;
}

</style>